<template>
  <div class='container'>
    <div class="header">
      <div class="options">
        <div :class="['option',activeIndex===1?'active':'']" @click="tabChange(1)">
          一级危机
        </div>
        <div :class="['option',activeIndex===2?'active':'']" @click="tabChange(2)">
          二级危机
        </div>
        <div :class="['option',activeIndex===3?'active':'']" @click="tabChange(3)">
          三级危机
        </div>
      </div>
      <div class="search">
        <van-icon name="search" size="4.8vw"/>
        <input type="text" v-model="listQuery.trueName" placeholder="请输入标题进行查找">
        <button @click="search">搜索</button>
      </div>
    </div>
    <div class="static-list" v-if="activeIndex===1">
      <div class="total">
        <p style="margin-left: 2.6667vw;">共<b style="margin: 0 1.3333vw;">{{recordCount}}</b>名学生存在预警</p>
        <div style="display: flex;align-items: center;" @click="isShow=true">
          <img src="@/assets/project/screen.png" alt="" style="width: 2.6667vw;height: 2.6667vw;margin-right: 1.3333vw;">
          <p style="color: #666;font-size: 2.9333vw;">筛选</p>
        </div>
      </div>
      <div class="list-content" v-for="item in list" :key="item.id">
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <p><b style="font-size: 4vw;margin-right: 2.6667vw;">{{item.trueName}}</b> <span style="background: #EFF9F8;padding: .8vw 2.1333vw;color:#10CA9B ;font-size: 2.9333vw;border-radius: .8vw;">{{transformGrade(item.grade)}} {{item.studyClass}}</span></p>
          <div :class="['status',item.fromType!==1?'':'warn']">
            <p>{{item.fromType===1?'测评预警':'联动反馈'}}</p>
          </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 1.3333vw;">
          <div class="time">
            <img src="@/assets/project/time.png" alt="">
            <p>{{item.created_at}}</p>
          </div>
        </div>
        <div class="btns" style="display: flex;align-items: center;">
          <button @click="$router.push({path:'/my/intervention',query:{id:item.id,level:0}})">干预</button>
          <button @click="isDialog=true">告知家长</button>
        </div>
      </div>
      <div ref="bottom" style="position: relative; height: .2667vw;"></div>
    </div>
    <div class="static-list" v-else-if="activeIndex===2">
      <div class="total">
        <p style="margin-left: 2.6667vw;">共<b style="margin: 0 1.3333vw;">{{recordCount}}</b>名学生存在预警</p>
        <div style="display: flex;align-items: center;" @click="isShow=true">
          <img src="@/assets/project/screen.png" alt="" style="width: 2.6667vw;height: 2.6667vw;margin-right: 1.3333vw;">
          <p style="color: #666;font-size: 2.9333vw;">筛选</p>
        </div>
      </div>
      <div class="list-content" v-for="item in list" :key="item.id">
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <p><b style="font-size: 4vw;margin-right: 2.6667vw;">{{item.trueName}}</b> <span style="background: #EFF9F8;padding: .8vw 2.1333vw;color:#10CA9B ;font-size: 2.9333vw;border-radius: .8vw;">{{transformGrade(item.grade)}} {{item.studyClass}}</span></p>
          <div :class="['status',item.fromType!==1?'':'warn']">
            <p>{{item.fromType===1?'测评预警':'联动反馈'}}</p>
          </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 4vw;">
          <div class="time">
            <img src="@/assets/project/time.png" alt="">
            <p>{{item.created_at}}</p>
          </div>
        </div>
        <div class="btns">
          <button @click="$router.push({path:'/my/intervention',query:{id:item.id,level:1}})">干预</button>
          <button @click="isDialog=true">告知家长</button>
          <button @click="reported(item.id)">上报预警</button>
        </div>
      </div>
      <div ref="bottom" style="position: relative; height: .2667vw;"></div>
    </div>
    <div class="static-list" v-else-if="activeIndex===3">
      <div class="total">
        <p style="margin-left: 2.6667vw;">共<b style="margin: 0 1.3333vw;">{{recordCount}}</b>名学生存在预警</p>
        <div style="display: flex;align-items: center;" @click="isShow=true">
          <img src="@/assets/project/screen.png" alt="" style="width: 2.6667vw;height: 2.6667vw;margin-right: 1.3333vw;">
          <p style="color: #666;font-size: 2.9333vw;">筛选</p>
        </div>
      </div>
      <div class="list-content" v-for="item in list" :key="item.id">
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <p><b style="font-size: 4vw;margin-right: 2.6667vw;">{{item.trueName}}</b> <span style="background: #EFF9F8;padding: .8vw 2.1333vw;color:#10CA9B ;font-size: 2.9333vw;border-radius: .8vw;">{{transformGrade(item.grade)}} {{item.studyClass}}</span></p>
          <div :class="['status',item.fromType!==1?'':'warn']">
            <p>{{item.fromType===1?'测评预警':'联动反馈'}}</p>
          </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 4vw;">
          <div class="time">
            <img src="@/assets/project/time.png" alt="">
            <p>{{item.created_at}}</p>
          </div>
        </div>
        <div class="btns" style="display: flex;align-items: center;">
          <button @click="$router.push({path:'/my/intervention',query:{id:item.id,level:0}})">干预</button>
          <button @click="isDialog=true">告知家长</button>
          <button @click="reported(item.id)">上报预警</button>
        </div>
      </div>
      <div ref="bottom" style="position: relative; height: .2667vw;"></div>
    </div>
    <van-empty description="暂无学生预警" v-if="list.length<=0"></van-empty>
    <van-popup v-model="isShow" position="right" :style="{ height: '100%',width:'75%' }" close-on-click-overlay>
      <div class="popup">
        <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 6.6667vw;">
          <p style="font-size: 4.2667vw;font-weight: 550;">列表筛选</p>
          <img src="@/assets/project/close.png" alt="" style="width: 6.4vw;height: 6.4vw;" @click="isShow=false">
        </div>
        <div class="popup-list">
          <div class="option">
            <!-- <p>姓名</p> -->
            <input type="text" v-model="listQuery.trueName" placeholder="姓名">
          </div>
          <div class="option">
            <!-- <p>年级</p> -->
            <div class="selectInput">
              <!-- <p class="select" @click="showPopup('grade')">{{ gradeName }}</p> -->
              <input type="text" v-model="gradeName" placeholder="年级" @click="showPopup('grade')" readonly>
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
          <div class="option select">
            <!-- <p>班级</p> -->
            <div class="selectInput">
              <input type="text" v-model="className" placeholder="班级" @click="showPopup('class')" readonly>
              <!-- <p class="select" @click="showPopup('class')">{{ listQuery.studyClass }}</p> -->
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
          <div class="option select" >
            <!-- <p>预警方式</p> -->
            <div class="selectInput">
              <input type="text" v-model="fromTypeName" placeholder="预警方式" @click="showPopup('fromType')" readonly>
              <!-- <p class="select"  @click="showPopup('fromType')">{{ fromTypeName }}</p> -->
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
        </div>
        <div class="btns">
          <button style="color: #47D5B2;" @click="reset">重置</button>
          <button @click="filterSearch">搜索</button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="isPopup" position="bottom" :style="{ height: '40%' }" >
      <van-picker
        title="选择年级"
        value-key="studyClass"
        show-toolbar
        :columns="classList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='class'"
      />
      <van-picker
        title="选择班级"
        value-key="gradeName"
        show-toolbar
        :columns="gradeList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='grade'"
      />
      <van-picker
        title="选择预警方式"
        value-key="title"
        show-toolbar
        :columns="fromTypeTypeList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='fromType'"
      />
    </van-popup>
    <van-dialog v-model="isDialog" title="温馨提示" :showConfirmButton="false">
      <div class="dialog">
        <img src="@/assets/dialog-bg.png"  style="width: 40vw;height: 40vw;margin: 5.3333vw 0 4vw 0;"/>
        <p>移动端暂未开放告知家长功能，</p>
        <p>如需对通知家长请使用PC端进行</p>
        <button @click="isDialog=false">我知道了</button>
      </div>
    </van-dialog>
    <Tabbar :activeIndex="2"></Tabbar>
  </div>
</template>

<script>
import Tabbar from '@/components/Tabbar'
import {bottomLoading} from '@/utils/util.js'
import {reportUp,orgRiskList} from '@/api/warning.js'
import {gradeAndStudyClass} from '@/api/report.js'
export default {
  components: {
    Tabbar,
  },
  data(){
    return{
      activeIndex:1,
      isShow:false,
      isDialog:false,
      isPopup:false,
      popupName:'',
      list:'',
      listQuery: {
          pageNum: 1,
          pageSize: 10,
          riskStatus: 1,
          fromType: '',
          trueName: '',
          grade: '',
          studyClass: '',
          warningLevel: this.$route.query.active
      },
      // 展示数据
      gradeName:null,
      className:null,
      fromTypeName:null,
      gradeList:[],
      classList:['全部'],
      examsScreenList:[],
      fromTypeTypeList:[{id:-1,title:'全部'},{id:1,title:'测评预警'},{id:2,title:'联动反馈'},],
      recordCount:0,
      totalPageCount:0,
    }
  },
  created(){
    if(this.$route.query.active){
      this.tabChange(Number(this.$route.query.active))
    }
    // this.getList()
    this.getGradeOrClass()
  },
  mounted(){
    bottomLoading(this.$refs.bottom,this.loadNextPage)
  },
  updated(){
  },
  methods:{
    async getList(){
      const formData={}
      for (const el in this.listQuery) {
          if (this.listQuery[el] !== -1 && this.listQuery[el] !== '') {
              formData[el] = this.listQuery[el]
          }
      }
      const res = await orgRiskList(formData)
      this.list=res.data.list
      this.totalPageCount=res.data.totalPageCount
      this.recordCount=res.data.recordCount
    },
    tabChange(index){
      this.listQuery= {
          pageNum: 1,
          pageSize: 10,
          riskStatus: 1,
          fromType: '',
          trueName: '',
          grade: '',
          studyClass: '',
          warningLevel: index
      }
      this.getList()
      this.activeIndex=index
    },
    reset(){
      this.listQuery= {
          pageNum: 1,
          pageSize: 10,
          riskStatus: 1,
          fromType: '',
          trueName: '',
          grade: '',
          studyClass: '',
          warningLevel: this.activeIndex
      }
      this.getList()
      this.isShow=false
    },
    search(){
      this.listQuery.pageNum=1
      this.getList()
      this.listQuery.trueName=''
    },
    filterSearch(){
      this.listQuery.pageNum=1
      this.getList()
      this.isShow=false
    },
    async reported(id){
      const res = await reportUp({id})
      this.$toast.success('上报成功')
    },
    showPopup(name){
      this.popupName=name
      this.isPopup=true
    },
    async getGradeOrClass(){
      const res = await gradeAndStudyClass()
      res.data.grades.unshift({grade:-1,gradeName:'全部'})
      this.gradeList=res.data.grades
    },
    // 筛选确认
    onConfirm(value){
      if(this.popupName==='fromType'){
        this.listQuery.fromType=value.id
        this.fromTypeName=value.title
      }else if(this.popupName==='class'){
        this.listQuery.studyClass=value.studyClass
        this.className=value.studyClass
      }else if(this.popupName==='grade'){
        this.listQuery.grade=value.grade
        this.gradeName=value.gradeName
        this.listQuery.studyClass=''
        const gradeList=this.gradeList.filter(item=>item.grade===value.grade)
        this.classList=gradeList[0].studyClassList
        this.classList.unshift({studyClass:'全部'})
        // this.classList.unshift('全部')
      }else if(this.popupName==='level'){
        this.listQuery.sysWarningLevel=value.id
        this.levelName=value.title
      }else if(this.popupName==='exams'){
        this.listQuery.examId=value.id
        this.examName=value.title
      }
      this.isPopup=false
      console.log(value);
    },
    transformGrade(grade){
      let gradeName=''
      this.gradeList.forEach(item => {
        if(item.grade===grade){
          // console.log(339,grade,item.gradeName);
          gradeName=item.gradeName
        }
      })
      return gradeName
    },
    async loadNextPage() {
      if(this.listQuery.pageNum<this.totalPageCount){
        this.listQuery.pageNum++
        const formData={}
        for (const el in this.listQuery) {
          if (this.listQuery[el] !== -1 && this.listQuery[el] !== '') {
              formData[el] = this.listQuery[el]
          }
        }
        const res = await orgRiskList(formData)
        this.totalPageCount=res.data.totalPageCount
        this.recordCount=res.data.recordCount
        this.list.push(...res.data.list)
      }
      // 在这里编写请求下一页数据的逻辑
      // 可以使用AJAX或其他方式向服务器发送请求
      // 将获取的数据添加到this.list数组中
      // 更新this.page变量为下一页的页码
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding: 4vw 3.2vw 16vw 3.2vw;
  background-color: #fff;
  min-height: 100%;
  .header{
    .options{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .option{
        padding: 0 4vw;
        margin-left: 4vw;
        flex: 1;
        // flex-shrink: 0;
        // width: 29.3333vw;
        height: 9.6vw;
        line-height: 9.6vw;
        text-align: center;
        // font-weight: 550;
        border-radius: 2.6667vw;
        background: #EFF9F8;
        font-size: 2.9333vw;
        &:nth-of-type(1){
          margin-left: 0;
        }
      }
      .option.active{
        // background-color: rgba(88, 88, 88, 1);
        font-weight: 550;
        color: #01C795 ;
      }
    }
    .search{
      margin-top: 4vw;
      padding: 0 .5333vw 0 4vw;
      display: flex;
      height: 9.6vw;
      align-items: center;
      background: #F5F7F9;
      border-radius: 13.3333vw;
      input{
        padding: 0 4vw;
        flex: 1;
        height: 100%;
        background: #F5F7F9;
        &::placeholder{
          color: #666 ;
          // font-weight: 550;
        }
      }
      button{
        padding: 0 6.6667vw;
        height: 8.5333vw;
        line-height: 8vw;
        color: rgba(255, 255, 255, 1);
        border-radius: 13.3333vw;
        background-color: #5EDBC9 ;
      }
    }
  }
  .total{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4vw 0;
    color: rgba(149, 149, 149, 1);
  }
  .static-list{
    .list-content{
      margin-bottom: 5.3333vw;
      padding: 4vw;
      border-radius: 2.6667vw;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0vw 0vw 2.6667vw 0vw rgba(187,187,187,0.15);
      .status{
        padding: 0 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 2.9333vw;
        width: 10.6667vw;
        height: 10.6667vw;
        border-radius: 1.3333vw;
        color: #5EDBC9 ;
        background: #E0FFFB;
        
      }
      .status.warn{
        color: #FB6D3F;
        background-color: #FEF9F3;
      }
      .btns{
        margin-top: 4vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        button{
          padding: 1.3333vw 5.3333vw;
          font-size: 3.2vw;
          border-radius: 1.0667vw;
          margin-right: 1.6vw;
          color: #fff;
          background-color: #5EDBC9;
          &:last-child{
            margin-right: 0;
          }
        }
      }
      .time{
        padding: 1.3333vw 2.6667vw;
        margin-right: 4vw;
        display: flex;
        align-items: center;
        color: #797979;
        font-size: 2.9333vw;
        background: #FEF9F3;
        border-radius: .8vw;
        img{
          width: 3.2vw;
          height: 3.2vw;
          margin-right: 2.1333vw;
        }
      }
      /* .status{
        margin-top: 4vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button{
          padding: 1.3333vw 4vw;
          font-size: 3.2vw;
          border-radius: 1.3333vw;
          border: .2667vw solid rgb(0, 0, 0);
          background-color: #fff;
          margin-right: 1.6vw;
          &:last-child{
            margin-right: 0;
          }
        }
      } */
    }
  }
  .popup{
    position: relative;
    padding: 5.8667vw 5.3333vw 13.8667vw 5.3333vw;
    .popup-list{
      height: 80vh;
      overflow: scroll;
      width: 100%;
      // margin-top: 6.6667vw;
      .option{
        display: flex;
        flex-direction: column;
        margin-bottom: 2.6667vw;
        color: #333;
        .selectInput{
          position: relative;
          input{
            padding-right: 9.3333vw;
            width: 100%;
          }
          .arrow{
            position: absolute;
            top: 53%;
            right:2.4vw;
            transform: translateY(-50%);
          }
        }
        input{
          margin-top:1.3333vw ;
          padding: 0 5.3333vw;
          height: 12.2667vw;
          background: #F5F7F9;
          border-radius: 13.3333vw;
          // border: .2667vw solid rgba(187, 187, 187, 1);
          &::placeholder{
            color: #666;
          }
        }
        .select{
          margin-top:1.3333vw ;
          padding: 0 2.6667vw;
          height: 11.4667vw;
          line-height: 11.4667vw;
          border: .2667vw solid rgba(187, 187, 187, 1);
        }
        p{
          font-weight: 550;
        }
      }
    }
    .btns{
      position: fixed;
      display: flex;
      align-items: center;
      padding: 0 6.6667vw;
      width: 100%;
      height: 16vw;
      left: 0;
      bottom: 0;
      button{
        flex: 1;
        font-size: 4.2667vw;
        color: #fff;
        height: 12.2667vw;
        line-height: 12.2667vw;
        background: #EFF9F8;
        border-radius: 13.3333vw;
        &:last-child{
          margin-left: 6.6667vw;
          background: #10CA9B;
        }
      }
    }
  }
  .dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3.4667vw;
    p{
      line-height: 6.4vw;
    }
    button{
      margin: 8vw;
      width: 45.3333vw;
      height: 11.2vw;
      color: #fff;
      line-height: 11.2vw;
      text-align: center;
      border-radius: 13.3333vw;
      background-color: #5EDBC9;
    }
  }
}
</style>